.container {
  /* width: 260px; */
  width: 220px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 15px;
  border-bottom: 1px solid #f6f6f6;
}

.category {
  padding: 0 15px 15px 15px;
}

.category p:last-child{
  margin: 0 10px 0 0 !important;
}
