.container {
  position: relative;
  height: 100vh;
}

.header {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 5%;
}

.rightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #ff9c20;
  border-radius: 10px;
  cursor: pointer;
}

.headerTextId{
  display: flex;
}

.headerId{
  font-size: 28px;
  margin-right: 20px;
}

.numberId{
  color: red;
}

.info {
  display: flex;
  height: 10vh;
  justify-content: space-between;
  padding: 10px 5%;
}

.category {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
}

.category > div, .category > a {
  margin: 10px !important;
  max-width: 160px;
  max-height: 200px !important;
  height: 200px !important;
}

.category > a div{
  max-width: 160px;
  max-height: 200px !important;
  height: 200px !important;
  margin: 0;
}

.footer {
  position: absolute;
  bottom: 30px;
  padding: 0 5%;
}

.popup {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100wh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}

.modal {
  padding: 20px;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
}

.modal p {
  font-size: 24px;
  margin-top: 20px;
}
