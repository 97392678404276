.container {
  margin: 10px 0;
}

.HistoryListWrapper{
  max-height: 380px !important;
  overflow-y: scroll;
  margin: 10px 0;
}

.HistoryListWrapper::-webkit-scrollbar {
  width: 30px;
}

/* Track */
.HistoryListWrapper::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 30px;
}

/* Handle */
.HistoryListWrapper::-webkit-scrollbar-thumb {
background: #ff9c20;
border-radius: 30px;
}

/* Handle on hover */
.HistoryListWrapper::-webkit-scrollbar-thumb:hover {
background: #ff9c10;
}

.topBox {
  display: flex;
  padding: 5px 0;
  width: 100%;
}

.total {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}

.total>div:nth-child(2){
  display: flex;
  justify-content: flex-end;
}

.listBox {
  display: flex;
  border-radius: 10px;
  margin: 1px 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  padding: 5px 0;
}

.list {
  flex: 1;
  padding: 0px 30px;
}
