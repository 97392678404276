.cantainer {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-around;
  padding: 30px 5%;
}

.filterLeft {
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  padding: 0 1% 5px 1%;
}

.content {
  display: flex;
  padding: 3px 15px;
}

.productBox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 32% 32% 32%;
  gap: 10px;
  margin-left: 15px;
  max-height: 560px;
}

.pagination li{
    list-style-type: none;
}

.paginate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
ul {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  padding: 0 20px;
  color: #ff9c20;
  width: 80%;
}

.pagination li {
  margin: 0 10px;
}

.pagination li {
  border: 1px solid #ff9c20;
  border-radius: 4px;
  cursor: pointer;
}

.pagination li a {
  padding: 2px 12px;
}

.selectedActive {
  background: #ff9c20;
}

.activeLink {
  color: #fff;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12rem;
}

.headerIdStyles{
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 10px;
}

.headerId {
  font-weight: bold;
  font-size: 24px;
}

.numberId {
  color: red;
  font-size: 28px;
  font-weight: bold;
}
