.container {
  width: 100%;
  max-width: 245px;
  /* height: 200px; */
  /* display: grid; */
  /* grid-template-rows: 50% 1fr 1fr 1fr; */
  background-color: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 180px;
}
.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.imgBox img {
  width: 100%;
  max-height: 140px;

  object-fit: contain;
}

.nameBox {
  align-self: center;
  max-width: 220px;
}

.nameBox h4{
 white-space: nowrap;
 overflow: hidden;
  text-overflow: ellipsis;
  max-width: 98%;
}

.priceBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnBox {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.btnBox div:last-child div{
  width: 60% !important;
}

.btnBox button{
  width: 75%;
}

.productInfo {
  width: 100%;
}

.productInfo .nameBox{
  margin-bottom: 8px;
}

.heartBox {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.heartBox:hover {
  opacity: 0.7;
}
